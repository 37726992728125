import React, { useState } from "react";
import { RiAttachment2 } from "react-icons/ri";
import Moment from "react-moment";
import { useLanguage } from "./language";
import { toast, Toaster } from "react-hot-toast";
const TimeLine = (props) => {
  const { t, lanArabic } = useLanguage(); // Use the useLanguage hook to access translations and lanArabic

  console.warn("PRPS: " + props);

  function getLangProfLabel(prof) {
    const langProfMapping = {
      1: "Beginner",
      1.2: "Early Intermediate",
      1.23: "Intermediate",
      1.24: "Fluent",
      1.5: "Native",
    };

    return langProfMapping[prof] || "-";
  }

  // function getScopeLabel(scope) {
  //   const scopeMapping = {
  //     1: "School",
  //     1.2: "Local",
  //     1.5: "International",
  //   };

  //   return scopeMapping[scope] || "-";
  // }

  // function getDurationLabel(duration) {
  //   const durationMapping = {
  //     0.1: "1 month",
  //     0.3: "3 months",
  //     0.6: "6 months",
  //     0.9: "9 months",
  //     1.2: "12 months",
  //   };

  //   return durationMapping[duration] || "-";
  // }

  // function getSocialRoleLabel(role) {
  //   const roleMapping = {
  //     1: "Volunteer",
  //     1.2: "Organized",
  //     1.5: "Donated",
  //   };

  //   return roleMapping[role] || "-";
  // }

  // function getPositionLabel(position) {
  //   const positionMapping = {
  //     1: "Participation",
  //     1.1: "3rd",
  //     1.2: "2nd",
  //     1.5: "1st",
  //   };

  //   return positionMapping[position] || "-";
  // }

  // function getLeadershipScopeLabel(scope) {
  //   const scopeMapping = {
  //     1: "Class",
  //     1.2: "Section",
  //     1.51: "House",
  //     1.52: "School",
  //   };

  //   return scopeMapping[scope] || "-";
  // }

  // function getSocietyRoleLabel(role) {
  //   const roleMapping = {
  //     1: "President",
  //     1.2: "Vice President",
  //     1.5: "Member",
  //   };

  //   return roleMapping[role] || "-";
  // }

  // function getEngagementLabel(engagement) {
  //   const engagementMapping = {
  //     1: "Light",
  //     1.2: "Moderate",
  //     1.4: "Heavy",
  //     1.5: "Extra",
  //   };

  //   return engagementMapping[engagement] || "-";
  // }

  function getScopeLabel(scope, isArabic) {
    const scopeMapping = isArabic
      ? { 1: "المدرسة", 1.2: "المحلي", 1.5: "دولي" }
      : { 1: "School", 1.2: "Local", 1.5: "International" };

    return scopeMapping[scope] || "-";
  }

  function getDurationLabel(duration, isArabic) {
    const durationMapping = isArabic
      ? {
          0.1: "1 شهر",
          0.3: "3 أشهر",
          0.6: "6 أشهر",
          0.9: "9 أشهر",
          1.2: "12 شهر",
        }
      : {
          0.1: "1 month",
          0.3: "3 months",
          0.6: "6 months",
          0.9: "9 months",
          1.2: "12 months",
        };

    return durationMapping[duration] || "-";
  }

  function getSocialRoleLabel(role, isArabic) {
    const roleMapping = isArabic
      ? { 1: "متطوع", 1.2: "منظم", 1.5: "متبرع" }
      : { 1: "Volunteer", 1.2: "Organized", 1.5: "Donated" };

    return roleMapping[role] || "-";
  }

  function getPositionLabel(position, isArabic) {
    const positionMapping = isArabic
      ? { 1: "المشاركة", 1.1: "الثالث", 1.2: "الثاني", 1.5: "الأول" }
      : { 1: "Participation", 1.1: "3rd", 1.2: "2nd", 1.5: "1st" };

    return positionMapping[position] || "-";
  }

  function getLeadershipScopeLabel(scope, isArabic) {
    const scopeMapping = isArabic
      ? { 1: "الصف", 1.2: "القسم", 1.51: "البيت", 1.52: "المدرسة" }
      : { 1: "Class", 1.2: "Section", 1.51: "House", 1.52: "School" };

    return scopeMapping[scope] || "-";
  }

  function getSocietyRoleLabel(role, isArabic) {
    const roleMapping = isArabic
      ? { 1: "رئيس", 1.2: "نائب الرئيس", 1.5: "عضو" }
      : { 1: "President", 1.2: "Vice President", 1.5: "Member" };

    return roleMapping[role] || "-";
  }

  function getEngagementLabel(engagement, isArabic) {
    const engagementMapping = isArabic
      ? { 1: "خفيف", 1.2: "متوسط", 1.4: "ثقيل", 1.5: "إضافي" }
      : { 1: "Light", 1.2: "Moderate", 1.4: "Heavy", 1.5: "Extra" };

    return engagementMapping[engagement] || "-";
  }

  function getLeadershipActivityLabel(name) {
    const labelMap = {
      9: "Student Council General Secretary",
      8.01: "Deputy Head Student",
      8.02: "House Captain",
      8.03: "Class Prefect",
      8.04: "Captain Sports Team",
      7: "Class Monitor",
      10: "Head Student",
    };

    return labelMap[name] || "-";
  }

  function getSocietyNameLabel(name) {
    const labelMap = {
      8: "STEM/STEAM Society",
      8.02: "Art Society",
      8.01: "Music Society",
      8.03: "Debates Society",
      8.04: "Drama/Theater Society",
      8.05: "Math Society",
      8.06: "Literature Society",
      8.07: "Environment Society",
      8.08: "Community Service Society",
      8.09: "Media & Publications Society",
      7: "Astronomy Society",
      8.1: "Sports Society",
      7.02: "Choir Society",
      7.03: "Cooking Society",
      7.04: "Yoga Club",
      7.05: "Book Club",
      7.06: "School Media Club",
      7.07: "Cardio Club",
      7.01: "Film Society",
      8.2: "School Magazine",
    };
    return labelMap[name] || "-";
  }

  function getSocialActivityLabel(name) {
    const labelMap = {
      8: "Clothes drive",
      8.01: "Charity collection",
      9: "Blood donation drive",
      9.01: "Hospital volunteer",
      8.02: "Trash clean-up",
      8.03: "Charity bake sale",
      8.04: "Food drive",
    };
    return labelMap[name] || "-";
  }

  function getEventManagementLabels(name) {
    const labelMap = {
      9: "Executive Council",
      8: "Logistics Team",
      8.1: "Registrations Team",
      8.2: "Socials Team",
      8.3: "Publications Team",
      8.4: "Finance Team",
      8.5: "Promotions Team",
      8.6: "Communications Team",
      8.7: "IT Team",
      8.8: "Security Team",
      8.9: "Operations Team",
    };
    return labelMap[name] || "-";
  }

  function getTalentActivityLabel(name) {
    const labelMap = {
      7: "Videography",
      7: "Gaming",
      7: "SEO",
      7: "GFX",
      7: "Kni,tting/Crochet",
      7: "Resin art",
      7: "Embroidery",
      7: "Photography",
      7: "Painting",
      7: "Drawing",
      7: "Sculpture",
      7: "Graphic Design",
      7: "Writing",
      7: "Reading",
      7: "Makeup",
      7: "Cooking",
      7: "Photography",
      7: "Piano",

      7: "Ukulele",
      7: "Violin",
      7: "Singing",
      7: "Flute",
      7: "Guitar",
      7: "Cycling",
      7: "Editing",
      7: "Jewellery Making",
      7: "Dancing",
      7: "Cardio",
      7: "Weightlifting",
      7: "Football",
      7: "Cricket",
      7: "Swimming",
      7: "Basketball",

      7: "Poetry",
      7: "Hockey",
      7: "Tennis",
      7: "Ballet",
      7: "Football",
      7: "Table Tennis",
      7: "Chess",
      7: "Horseriding",

      7: "Skating",
      7: "Social Media Influencer",

      7: "Badminton",
    };
    return labelMap[name] || "-";
  }

  function getAwardsLabel(name) {
    const awardsMapping = {
      10: "Full attendance",
      10.01: "Academic excellence",
      10.02: "Sports scholarship",
      10.03: "Merit scholarship",
      10.04: "Research grant",
      7: "Poem Recitation",
      7.01: "Spoken Word Contest",
      8: "Play/Musical",
      7.02: "Choir Performance",
      7.03: "Aerobics Competition",
      7.04: "Bake Sale",
      7.05: "Artbeat",
      8.01: "Debates Competition",
      8.02: "School/college MUN",
      8.03: "Math Competition",
      7.06: "Trivia Competition",
      8.04: "Science Competition",
      8.05: "Science Fair",
      7.07: "Dance Competition",
      8.04: "Short Film Competition",
      8.05: "Robotics Competition",
      7.08: "Poster Making Competition",
      7.09: "Gaming Competition",
      7.1: "Fashion Design Competition",
      7.11: "Cooking Competition",
      7.12: "Modeling Competition",
      7.13: "Talent Show",
      8.06: "Handwriting Competition",
      8.07: "Essay Competition",
      7.14: "Acting Competition",
      8.08: "Drawing Competition",
      8.09: "Photography Competition",
      8.1: "Painting Competition",
      8.11: "Composition Competition",
      6: "Lucky Draw",
      7.15: "Spelling Competition",
      10.05: "Certificate of Achievement",
    };

    return awardsMapping[name] || "-";
  }

  function getSportsLabel(name) {
    const sportsMapping = {
      7: "Basketball",
      7.01: "Cricket",
      7.02: "Hockey",
      7.03: "Badminton",
      7.04: "Tennis",
      7.05: "Throwball",
      7.06: "Gymnastics",
      7.07: "Futsal",
      7.08: "Archery",
      7.09: "Cycling",
      7.1: "Sprint",
      7.2: "Football",
      7.3: "Relay race",
      7.4: "Baseball",
      7.5: "Fencing",
      7.6: "Golf",
      7.7: "Handball",
      7.8: "Netball",
      7.9: "Rowing",
      7.1: "Skateboarding",
      7.11: "Road bicycle racing",
      7.411: "Ballet",
      7.12: "Kabaddi",
      7.13: "Kickboxing",
      7.14: "Boxing",
      7.15: "Strongman",
      7.16: "Lacrosse",
      7.17: "Polo",
      7.18: "Water Polo",
      7.19: "Long Jump",
      7.2: "High Jump",
      7.21: "Shot put",
      7.22: "Pole Vault",
      7.23: "Pickleball",
      8: "Chess",
      7.24: "Track & field",
      7.25: "Curling",
      7.26: "Endurance running",
      7.27: "Hurdle races",
      7.28: "Ice skating",
      7.29: "Surfing",
      7.3: "Skiing",
      7.31: "Snowboarding",
      7.32: "Dodgeballt",
      7.33: "Climbing",
      7.34: "Snooker",
      7.35: "Squash",
      7.36: "Swimming",
      7.37: "Volleyball",
      7.38: "Weightlifting",
      7.39: "Rugby",
    };

    return sportsMapping[name] || "-";
  }

  function getInternshipTypeLabel(name) {
    const internshipTypeMapping = {
      1: "Part Time Internship",
      1.2: "Virtual Internship",
      1.5: "On-site Internship",
    };
    return internshipTypeMapping[name] || "-";
  }

  function getCertificationLabel(type) {
    const certificationMapping = {
      10: "First aid certification",
      5: "Driving license",
      8: "Google certification",
      8.01: "Coursera course",
      8.02: "edX course",
      8.03: "Udemy course",
      8.04: "Masterclass course",
      8.05: "Duolingo course",
      8.06: "Khan Academy course",
      8.07: "Codecademy course",
      8.08: "Domestika course",
      8.09: "Udacity course",
    };

    return certificationMapping[type] || "-";
  }

  function getLanguageLabel(name) {
    const languageMapping = {
      af: "Afrikaans",
      sq: "Albanian - shqip",
      am: "Amharic - አማርኛ",
      ar: "Arabic - العربية",
      an: "Aragonese - aragonés",
      hy: "Armenian - հայերեն",
      ast: "Asturian - asturianu",
      az: "Azerbaijani - azərbaycan dili",
      eu: "Basque - euskara",
      be: "Belarusian - беларуская",
      bn: "Bengali - বাংলা",
      bs: "Bosnian - bosanski",
      br: "Breton - brezhoneg",
      bg: "Bulgarian - български",
      ca: "Catalan - català",
      ckb: "Central Kurdish - کوردی (دەستنوسی عەرەبی)",
      zh: "Chinese - 中文",
      "zh-HK": "Chinese (Hong Kong) - 中文（香港）",
      "zh-CN": "Chinese (Simplified) - 中文（简体）",
      "zh-TW": "Chinese (Traditional) - 中文（繁體）",
      co: "Corsican",
      hr: "Croatian - hrvatski",
      cs: "Czech - čeština",
      da: "Danish - dansk",
      nl: "Dutch - Nederlands",
      eo: "Esperanto - esperanto",
      et: "Estonian - eesti",
      fo: "Faroese - føroyskt",
      fil: "Filipino",
      fi: "Finnish - suomi",
      fr: "French - français",
      "fr-CA": "French (Canada) - français (Canada)",
      "fr-FR": "French (France) - français (France)",
      "fr-CH": "French (Switzerland) - français (Suisse)",
      gl: "Galician - galego",
      ka: "Georgian - ქართული",
      de: "German - Deutsch",
      "de-AT": "German (Austria) - Deutsch (Österreich)",
      "de-DE": "German (Germany) - Deutsch (Deutschland)",
      "de-LI": "German (Liechtenstein) - Deutsch (Liechtenstein)",
      "de-CH": "German (Switzerland) - Deutsch (Schweiz)",
      el: "Greek - Ελληνικά",
      gn: "Guarani",
      gu: "Gujarati - ગુજરાતી",
      ha: "Hausa",
      haw: "Hawaiian - ʻŌlelo Hawaiʻi",
      he: "Hebrew - עברית",
      hi: "Hindi - हिन्दी",
      hu: "Hungarian - magyar",
      is: "Icelandic - íslenska",
      id: "Indonesian - Indonesia",
      ia: "Interlingua",
      ga: "Irish - Gaeilge",
      it: "Italian - italiano",
      "it-IT": "Italian (Italy) - italiano (Italia)",
      "it-CH": "Italian (Switzerland) - italiano (Svizzera)",
      ja: "Japanese - 日本語",
      kn: "Kannada - ಕನ್ನಡ",
      kk: "Kazakh - қазақ тілі",
      km: "Khmer - ខ្មែរ",
      ko: "Korean - 한국어",
      ku: "Kurdish - Kurdî",
      ky: "Kyrgyz - кыргызча",
      lo: "Lao - ລາວ",
      la: "Latin",
      lv: "Latvian - latviešu",
      ln: "Lingala - lingála",
      lt: "Lithuanian - lietuvių",
      mk: "Macedonian - македонски",
      ms: "Malay - Bahasa Melayu",
      ml: "Malayalam - മലയാളം",
      mt: "Maltese - Malti",
      mr: "Marathi - मराठी",
      mn: "Mongolian - монгол",
      ne: "Nepali - नेपाली",
      no: "Norwegian - norsk",
      nb: "Norwegian Bokmål - norsk bokmål",
      nn: "Norwegian Nynorsk - nynorsk",
      oc: "Occitan",
      or: "Oriya - ଓଡ଼ିଆ",
      om: "Oromo - Oromoo",
      ps: "Pashto - پښتو",
      fa: "Persian - فارسی",
      pl: "Polish - polski",
      pt: "Portuguese - português",
      "pt-BR": "Portuguese (Brazil) - português (Brasil)",
      "pt-PT": "Portuguese (Portugal) - português (Portugal)",
      pa: "Punjabi - ਪੰਜਾਬੀ",
      qu: "Quechua",
      ro: "Romanian - română",
      mo: "Romanian (Moldova) - română (Moldova)",
      rm: "Romansh - rumantsch",
      ru: "Russian - русский",
      gd: "Scottish Gaelic",
      sr: "Serbian - српски",
      sh: "Serbo-Croatian - Srpskohrvatski",
      sn: "Shona - chiShona",
      sd: "Sindhi",
      si: "Sinhala - සිංහල",
      sk: "Slovak - slovenčina",
      sl: "Slovenian - slovenščina",
      so: "Somali - Soomaali",
      st: "Southern Sotho",
      es: "Spanish - español",
      "es-AR": "Spanish (Argentina) - español (Argentina)",
      "es-419": "Spanish (Latin America) - español (Latinoamérica)",
      "es-MX": "Spanish (Mexico) - español (México)",
      "es-ES": "Spanish (Spain) - español (España)",
      "es-US": "Spanish (United States) - español (Estados Unidos)",
      su: "Sundanese",
      sw: "Swahili - Kiswahili",
      sv: "Swedish - svenska",
      tg: "Tajik - тоҷикӣ",
      ta: "Tamil - தமிழ்",
      tt: "Tatar",
      te: "Telugu - తెలుగు",
      th: "Thai - ไทย",
      ti: "Tigrinya - ትግርኛ",
      to: "Tongan - lea fakatonga",
      tr: "Turkish - Türkçe",
      tk: "Turkmen",
      tw: "Twi",
      uk: "Ukrainian - українська",
      ug: "Uyghur",
      uz: "Uzbek - o‘zbek",
      vi: "Vietnamese - Tiếng Việt",
      wa: "Walloon - wa",
      cy: "Welsh - Cymraeg",
      fy: "Western Frisian",
      xh: "Xhosa",
      yi: "Yiddish",
      yo: "Yoruba - Èdè Yorùbá",
      zu: "Zulu - isiZulu",
    };
    return languageMapping[name] || "-";
  }

  const [image, setImage] = useState();

  const submitImage = async (e, id, category) => {
    const file = e.target.files[0];
    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "sda7kxdr");
    data.append("cloud_name", "doxi2odzm");
    const response = await fetch(
      "https://api.cloudinary.com/v1_1/doxi2odzm/image/upload",
      {
        method: "POST",
        body: data,
      }
    );
    if (!response.ok) {
      // alert("Image Upload Failed");
      throw new Error("Image upload failed");
    } else {
      const sendData = await response.json();
      // alert("Success", sendData.secure_url);
      toast.success("Proof sent to admin");
      console.log("URL---", data.secure_url);
      const dataToSend = {
        image: sendData.secure_url,
        activityId: id,
        category: category,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_KEY}/api/upload_pic/`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataToSend),
        }
      );
      props.status = 0;
    }
  };



  

  return (
    <>
      {/* Timeline */}
      <div>
        {/* Heading */}


        
        <div className="ps-2 my-2 first:mt-0">
          <h5 className="text-xs font-medium uppercase text-gray-500 darkI:text-gray-400">
            {props.startDate !== "0" ? (
              <div className="btn p-0 tn-sm btn-ghost hover:bg-transparent no-animation items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-4 h-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
                  />
                </svg>
                {props.startDate}
              </div>
            ) : (
              <div className="btn p-0 tn-sm btn-ghost hover:bg-transparent no-animation items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-4 h-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z"
                  />
                </svg>
                Miscellaneous
              </div>
            )}
          </h5>
        </div>
        {/* End Heading */}
        {/* Item */}
        <div className="flex gap-x-3">
          {/* Icon */}
          <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 darkI:after:bg-gray-700">
            <div className="relative z-10 w-7 h-7 flex justify-center items-center">
              <div className="w-2 h-2 rounded-full bg-gray-400 darkI:bg-gray-600" />
            </div>
          </div>
          {/* End Icon */}
          {/* Right Content */}
          <div className="grow pt-0.5 pb-2">
            {(() => {
              if (props.category === "LeadershipActivities") {
                return (
                  <span class="inline-flex h-fit items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-gray-100 text-gray-800 darkI:bg-white/10 darkI:text-white mb-4">
                    🏆 {("Leadership")}
                  </span>
                );
              } else if (props.category === "SocietyActivities") {
                return (
                  <span class="inline-flex h-fit items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-gray-100 text-gray-800 darkI:bg-white/10 darkI:text-white mb-4">
                    ♣️ {("Student Club")}
                  </span>
                );
              } else if (props.category === "AwardActivities") {
                return (
                  <span class="inline-flex h-fit items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-gray-100 text-gray-800 darkI:bg-white/10 darkI:text-white mb-4">
                    ♣️ {("Award")}
                  </span>
                );
              } else if (props.category === "SocialActivities") {
                return (
                  <span class="inline-flex h-fit items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-gray-100 text-gray-800 darkI:bg-white/10 darkI:text-white mb-4">
                    🎗️ {("Social Work")}
                  </span>
                );
              } else if (props.category === "SportsActivities") {
                return (
                  <span class="inline-flex h-fit items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-gray-100 text-gray-800 darkI:bg-white/10 darkI:text-white mb-4">
                    ⚽ {("Sports")}
                  </span>
                );
              } else if (props.category === "InternActivities") {
                return (
                  <span class="inline-flex h-fit items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-gray-100 text-gray-800 darkI:bg-white/10 darkI:text-white mb-4">
                    💼 {("Internship")}
                  </span>
                );
              } else if (props.category === "CertActivities") {
                return (
                  <span class="inline-flex h-fit items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-gray-100 text-gray-800 darkI:bg-white/10 darkI:text-white mb-4">
                    📜 {("Certification")}
                  </span>
                );
              } else if (props.category === "LangActivities") {
                return (
                  <span class="inline-flex h-fit items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-gray-100 text-gray-800 darkI:bg-white/10 darkI:text-white mb-4">
                    🔠 {("Languages")}
                  </span>
                );
              } else if (props.category === "TalentActivities") {
                return (
                  <span class="inline-flex h-fit items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-gray-100 text-gray-800 darkI:bg-white/10 darkI:text-white mb-4">
                    🎨 {("Talents")}
                  </span>
                );
              } else if (props.category === "EventsActivities") {
                return (
                  <span class="inline-flex h-fit items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-gray-100 text-gray-800 darkI:bg-white/10 darkI:text-white mb-4">
                    📆 {("Event Management")}
                  </span>
                );
              }
            })()}

            <h5 className="flex gap-x-1.5 font-semibold text-gray-800 darkI:text-white">
              {props.title}

              {/* {props.status === "0" && (
                <span className="inline-flex h-fit items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-yellow-500 text-white">
                  Pending
                </span>
              )}

              {props.status === "1" && (
                <span className="inline-flex h-fit items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-teal-500 text-white">
                  Approved
                </span>
              )}

              {props.status === "3" && (
                <div>
                  <span className="inline-flex h-fit items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-orange-500 text-white">
                    Need Proof{" "}
                  </span>
                </div>
              )}

              {props.status === "2" && (
                <span className="inline-flex h-fit items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-red-500 text-white">
                  Rejected
                </span>
              )} */}
            </h5>

            <p className="mt-1 text-sm text-gray-600 darkI:text-gray-400">
              <span class="inline-flex h-fit items-center gap-x-1.5 py-1.5 mr-2 px-3 rounded-full text-xs font-medium border border-gray-200 bg-white text-gray-800 shadow-sm darkI:bg-slate-900 darkI:border-gray-700 darkI:text-white my-2">
                Grade {props.grade}
              </span>

              {(() => {
                if (props.months > 0) {
                  return (
                    <span class="inline-flex h-fit items-center gap-x-1.5 py-1.5 mr-2 px-3 rounded-full text-xs font-medium border border-gray-200 bg-white text-gray-800 shadow-sm darkI:bg-slate-900 darkI:border-gray-700 darkI:text-white my-2">
                      {props.months} month(s) duration
                    </span>
                  );
                }
              })()}

              {(() => {
                if (props.scope > 0 && props.scope !== "-") {
                  return (
                    <span class="inline-flex h-fit items-center gap-x-1.5 py-1.5 mr-2 px-3 rounded-full text-xs font-medium border border-gray-200 bg-white text-gray-800 shadow-sm darkI:bg-slate-900 darkI:border-gray-700 darkI:text-white my-2">
                      Inside {getLeadershipScopeLabel(props.scope)}
                    </span>
                  );
                }
              })()}

              {(() => {
                if (props.service_hours > 0) {
                  return (
                    <span class="inline-flex h-fit items-center gap-x-1.5 py-1.5 mr-2 px-3 rounded-full text-xs font-medium border border-gray-200 bg-white text-gray-800 shadow-sm darkI:bg-slate-900 darkI:border-gray-700 darkI:text-white my-2">
                      {" "}
                      {getEngagementLabel(props.service_hours)} weekly
                      engagement
                    </span>
                  );
                }
              })()}
            </p>

            {(() => {
              if (
                props.feedback !== "0" &&
                props.feedback !== "" &&
                props.feedback !== null
              ) {
                return (
                  <span class="inline-flex h-fit items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium bg-blue-100 text-blue-800 darki:bg-blue-800/30 darki:text-blue-500">
                    {props.feedback}
                  </span>
                );
              }
            })()}

            {props.status === "3" && (
              <div>
                <form>
                  <label class="block">
                    <span class="sr-only">Choose profile photo</span>
                    <input
                      type="file"
                      class="block w-full text-sm text-gray-500
      file:me-4 file:py-2 file:px-4
      file:rounded-lg file:border-0
      file:text-sm file:font-semibold
      file:bg-blue-600 file:text-white
      hover:file:bg-blue-700
      file:disabled:opacity-50 file:disabled:pointer-events-none
      dark:file:bg-orange-500
      dark:hover:file:bg-blue-400
      bg-[#fdf0d7]
      text-[#977c4a]
      rounded-lg
      p-4
      border-2 border-[#e3d7c15c ]
    "
                      onChange={(e) =>
                        submitImage(e, props.activityId, props.category)
                      }
                    />
                  </label>
                </form>
              </div>
            )}
          </div>
          {/* End Right Content */}
        </div>

        {/* End Item */}
      </div>
      {/* End Timeline */}
    </>
  );
};

export default TimeLine;
